<template>
  <div class="text-center">
    <v-menu
      v-if="
        !$store.getters.authenticated || is_email || is_login || is_register
      "
      v-model="login_menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      origin="center center"
      transition="scale-transition"
    >
      <!-- Menu Button -->
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
          <v-icon> mdi-lock </v-icon>
        </v-btn>
      </template>

      <!-- Base -->
      <v-card v-if="!is_register && !is_login && !is_email && require_login">
        <v-list class="ma-0">
          <v-list-item>
            <v-list-item-content
              :style="{ textAlignLast: 'center' }"
              class="pa-0 ma-0"
            >
              <v-list-item-title>Authorization Required</v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-container>
          <v-form v-model="valid" v-on:submit.prevent ref="login_form">
            <v-list class="pb-0 pt-0">
              <v-list-item :style="{ marginRight: '0px' }">
                <v-text-field
                  v-model="collection.username"
                  :rules="$store.getters.rules.auth_totp.username"
                  :counter="16"
                  label="Username"
                  autofocus
                  required
                  class="pb-0 pt-0"
                ></v-text-field>
              </v-list-item>

              <v-list-item :style="{ marginRight: '0px' }">
                <v-text-field
                  type="password"
                  v-model="collection.password"
                  :rules="$store.getters.rules.auth_totp.password"
                  :counter="36"
                  label="Password"
                  required
                  class="pb-0 pt-0"
                ></v-text-field>
              </v-list-item>

              <v-list-item
                :style="{ textAlignLast: 'center', marginRight: '0px' }"
                v-if="error_msg != ''"
              >
                <v-list-item-subtitle
                  :style="{
                    whiteSpace: 'normal',
                    color: 'red',
                    marginTop: '15px',
                  }"
                  >{{ error_msg }}</v-list-item-subtitle
                >
              </v-list-item>
            </v-list>
          </v-form>
        </v-container>

        <v-card-actions>
          <v-btn text @click="register" v-if="allow_registration">
            Register
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="login"> Login </v-btn>
        </v-card-actions>
      </v-card>

      <!-- Secret -->
      <v-card v-if="is_register && allow_registration">
        <v-list>
          <v-list-item>
            <v-list-item-content
              :style="{ textAlignLast: 'center' }"
              class="pa-0 ma-0"
            >
              <v-list-item-title>Scan QR Code</v-list-item-title>
              <v-list-item-subtitle>
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
                  target="_blank"
                  >Google Authenticator</a
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-content
              :style="{ textAlignLast: 'center' }"
              class="pa-0 ma-0"
            >
              <v-img
                width="200"
                height="200"
                :src="
                  'https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=' +
                  'otpauth%3A//totp/my-dev.app%3A' +
                  collection.username +
                  '%2540my-dev%3Fsecret%3D' +
                  collection.secret +
                  '%26issuer%3Dmy-dev.app&choe=UTF-8'
                "
              ></v-img>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content
              :style="{ textAlignLast: 'center' }"
              class="pa-0 ma-0"
            >
              <v-list-item-title :style="{ fontSize: 'small' }">
                <code>{{ $data.collection.secret }}</code>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                text
                @click="copySecret"
              >
                Copy
              </v-btn>
            </template>
            <span>{{ $data.collection.secret }}</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="
              is_register = false;
              is_login = true;
            "
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- 2FA -->
      <v-card v-if="is_login || (!is_email && !require_login)">
        <v-list>
          <v-list-item>
            <v-list-item-content
              :style="{ textAlignLast: 'center' }"
              class="pa-0 ma-0"
            >
              <v-list-item-title>Provide 2FA from</v-list-item-title>
              <v-list-item-subtitle>
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
                  target="_blank"
                  >Google Authenticator</a
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-container :style="{ textAlignLast: 'center' }">
              <v-avatar size="125">
                <v-img
                  :src="require('@/assets/images/auth_totp/otp.png')"
                ></v-img>
              </v-avatar>
            </v-container>
          </v-list-item>
        </v-list>

        <v-container>
          <v-form v-model="valid" v-on:submit.prevent ref="otp_form">
            <v-list class="pb-0 pt-0">
              <v-list-item
                :style="{ marginRight: '0px' }"
                v-if="!require_login"
              >
                <v-text-field
                  v-model="collection.username"
                  :rules="$store.getters.rules.auth_totp.username"
                  :counter="16"
                  label="Username"
                  :autofocus="!require_login"
                  required
                  class="pb-0 pt-0"
                ></v-text-field>
              </v-list-item>

              <v-list-item :style="{ marginRight: '0px' }">
                <v-text-field
                  v-model="collection.otp"
                  :rules="$store.getters.rules.auth_totp.otp"
                  :counter="6"
                  label="2FA Connection Code"
                  class="pb-0 pt-0"
                  :autofocus="require_login"
                  required
                ></v-text-field>
              </v-list-item>

              <v-list-item
                :style="{ textAlignLast: 'center', marginRight: '0px' }"
                v-if="error_msg != ''"
              >
                <v-list-item-subtitle
                  :style="{
                    whiteSpace: 'normal',
                    color: 'red',
                    marginTop: '15px',
                  }"
                  >{{ error_msg }}</v-list-item-subtitle
                >
              </v-list-item>
            </v-list>
          </v-form>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="checkAuthenticator">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- Email -->
      <v-card v-if="is_email">
        <v-list>
          <v-list-item>
            <v-list-item-content
              :style="{ textAlignLast: 'center' }"
              class="pa-0 ma-0"
            >
              <v-list-item-title>Connect E-mail to</v-list-item-title>
              <v-list-item-subtitle>
                {{ collection.username }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-container>
          <v-form v-model="valid" v-on:submit.prevent ref="email_form">
            <v-list class="pb-0 pt-0">
              <v-list-item :style="{ marginRight: '0px' }">
                <v-text-field
                  v-model="collection.email"
                  :rules="$store.getters.rules.auth_totp.email"
                  label="E-Mail Address"
                  class="pb-0 pt-0"
                  autofocus
                  required
                ></v-text-field>
              </v-list-item>

              <v-list-item
                :style="{ textAlignLast: 'center', marginRight: '0px' }"
                v-if="error_msg != ''"
              >
                <v-list-item-subtitle
                  :style="{
                    whiteSpace: 'normal',
                    color: 'red',
                    marginTop: '15px',
                  }"
                  >{{ error_msg }}</v-list-item-subtitle
                >
              </v-list-item>
            </v-list>
          </v-form>
        </v-container>

        <v-card-actions>
          <v-btn
            color="accent"
            text
            @click="skipEmail"
            v-if="allow_skipping_email"
          >
            Skip
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="updateEmail"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-menu
      v-if="
        $store.getters.authenticated && !is_email && !is_login && !is_register
      "
      v-model="auth_menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-Y
      transition="slide-y-transition"
    >
      <!-- Auth Menu -->
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
          <v-icon> mdi-menu </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-btn text @click="logout" :style="{ width: '100%' }">
            <v-icon> mdi-logout-variant </v-icon>
            <v-spacer></v-spacer>
            Logout
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import axios from "axios";

function initialState() {
  return {
    login_menu: false, // Indicator if login menu is collapsed (false) or expanded (true)
    auth_menu: false, // Indicator if auth menu is collapsed (false) or expanded (true)
    valid: false, // Indicator if the login form is filled in correctly

    is_register: false, // If true the user creates a new account
    is_login: false, // If true the user is logging in
    is_email: false, // If true ask user for e-mail

    // This section is obtained from the backend
    allow_registration: true,
    allow_skipping_email: true,
    require_email: true,
    require_login: true,
    /////////

    error_msg: "", // Hold authentication error message

    collection: {
      // Contains data collected through out the wizard
      username: "", // The username of the account
      known_email: null, // Holds the known email
      email: "", // Holds the email of the account
      password: "", // The password of the account
      secret: "", // Contains secret if present
      otp: "", // Contains the otp (ID) if presents
    },
  };
}

export default {
  name: "AuthorizeModify",

  props: {
    push: {
      // When authorized, push to this router endpoint
      type: String,
      default: "dashboard",
    },
  },

  data: function () {
    return initialState();
  },

  created() {
    this.clear_state();
  },

  methods: {
    clear_state() {
      // Resets $data on closing dialog
      Object.assign(this.$data, initialState());
      axios
        .get("/api/v0/auth/totp/settings")
        .then((response) => {
          if (response.status == 200) {
            this.$data.allow_registration = response.data.allow_registration;
            this.$data.allow_skipping_email =
              response.data.allow_skipping_email;
            this.$data.require_email = response.data.require_email;
            this.$data.require_login = response.data.require_login;
          }
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            console.error(e.response.data.message);
          }
        });
    },
    login() {
      if (this.$data.require_login) {
        this.$data.error_msg = "";
        if (this.$refs.login_form.validate()) {
          const params = new URLSearchParams();
          params.append("username", this.$data.collection.username);
          params.append("password", this.$data.collection.password);

          axios
            .post("/api/v0/auth/totp/login", params)
            .then((response) => {
              if (response.data.code == 200) {
                this.$data.is_login = true;
              }
            })
            .catch((e) => {
              if (e.response.data["message"]) {
                console.error(e.response.data.message);
                this.$data.error_msg = e.response.data.message;
              }
            });
        }
      }
    },

    register() {
      if (this.$data.allow_registration) {
        // Reset required values
        this.$data.error_msg = "";
        this.$data.is_register = false;
        this.$data.is_login = false;

        // Check if form is correct
        if (this.$refs.login_form.validate()) {
          const params = new URLSearchParams();
          params.append("username", this.$data.collection.username);
          params.append("password", this.$data.collection.password);
          // Make call
          axios
            .post("/api/v0/auth/totp/register", params)
            .then((response) => {
              if (response.data["message"]) {
                console.error(response.data.message);
              }
              if (response.data["secret"]) {
                this.$data.collection.secret = response.data["secret"];
                this.$data.error_msg = "";
                this.$data.is_register = true;
              } else if (response.status == 200) {
                this.$data.error_msg = "";
                this.$data.is_login = true;
              }
            })
            .catch((e) => {
              if (e.response.data["message"]) {
                console.error(e.response.data.message);
                this.$data.error_msg = e.response.data.message;
              }
            });
        }
      }
    },

    checkAuthenticator() {
      // Checks if 2FA is valid
      this.$data.error_msg = "";

      // Check if form is correct
      if (this.$refs.otp_form.validate()) {
        // Make call
        axios
          .post("/api/v0/auth/totp/2fa", {
            username: this.$data.collection.username,
            otp: parseInt(this.$data.collection.otp),
          })
          .then((response) => {
            if (response.data["message"]) {
              console.error(response.data.message);
            }

            if (response.data["access_token"]) {
              // Authenticate
              this.$store.commit("setAccessToken", response.data.access_token);
            }
            if (this.$data.require_email) {
              this.startEmail();
            } else {
              this.skipEmail();
            }
          })
          .catch((e) => {
            if (e.response.data["message"]) {
              console.error(e.response.data.message);
              this.$data.error_msg = e.response.data.message;
            }
          });
      }
    },

    startEmail() {
      axios
        .get("/api/v0/users/me", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.access_token,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$data.collection.known_email = response.data.email;
            this.$store.commit("setEmail", response.data.email);
            this.$store.commit("setUsername", response.data.username);
          }
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            console.error(e.response.data.message);
          }
        })
        .finally(() => {
          if (
            this.$store.getters.account.email == "" ||
            this.$store.getters.account.email == null
          ) {
            this.$data.is_login = false;
            this.$data.is_email = true;
          } else {
            this.skipEmail();
          }
        });
    },

    skipEmail() {
      if (
        !this.$data.require_email ||
        (this.$data.require_email &&
          this.$data.collection.known_email != null &&
          this.$data.collection.known_email.trim() != "") ||
        this.$data.allow_skipping_email
      ) {
        this.finalize();
      }
    },

    updateEmail() {
      if (this.$refs.email_form.validate()) {
        this.$store.commit("setEmail", this.$data.collection.email);
        axios
          .post(
            "/api/v0/users/update",
            {
              email: this.$data.collection.email,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.access_token,
              },
            }
          )
          .then((response) => {
            if (response.status == 200) {
              this.$data.collection.known_email = response.data.email;
              this.$store.commit("setEmail", response.data.email);
              this.$store.commit("setUsername", response.data.username);
            }
          })
          .catch((e) => {
            if (e.response.data["message"]) {
              console.error(e.response.data.message);
            }
          })
          .finally(() => {
            this.finalize();
          });
      }
    },

    finalize() {
      axios
        .get("/api/v0/users/me", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.access_token,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$store.commit("setUsername", response.data.username);
          }
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            console.error(e.response.data.message);
          }
        })
        .finally(() => {
          this.$data.login_menu = false;
          if (this.$props.push.trim() != "") {
            this.$router.push(this.$props.push);
          }
        });
    },

    logout() {
      axios
        .get("/api/v0/auth/totp/logout")
        .catch((e) => {
          if (e.response.data["message"]) {
            console.error(e.response.data.message);
          }
        })
        .finally(() => {
          this.$data.login_menu = false;
          this.$store.dispatch("clearState");
          this.$router.push("/");
        });
    },

    async copySecret() {
      // Copies current secret to clipboard
      await navigator.clipboard.writeText(this.$data.collection.secret);
    },
  },

  watch: {
    login_menu: function (value) {
      // Clears search history when closing the dialog
      if (!value) {
        this.clear_state();
      }
    },
  },
};
</script>
