import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  invite_code_censorship: null,
};

const getters = {
  invite_code_censorship: (state) => state.invite_code_censorship,
};

const actions = {
  clearState({ commit }) {
    commit("resetCensorshipState");
  },
};

const mutations = {
  resetCensorshipState(state) {
    state.invite_code_censorship = null;
  },
  setInviteCode(state, value) {
    state.invite_code_censorship = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
