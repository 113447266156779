<template>
  <v-card flat outlined @click.native="error_msg = ''" :ripple="false" native>
    <v-card-title>Censorship Settings</v-card-title>
    <v-divider></v-divider>
    <v-tabs v-model="currentTab" centered icons-and-text grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab>
        Censored Tags
        <v-icon>mdi-tag</v-icon>
      </v-tab>

      <v-tab>
        Invite Codes
        <v-icon>mdi-account-multiple-plus</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <v-card flat outlined>
          <v-data-table
            dense
            :headers="tableHeadersTags"
            :items="tableContentTags"
            :items-per-page="5"
            class="elevation-0"
          >
            <template v-slot:[`item.created`]="{ item }">
              {{ convert_table_date(item.created) }}
            </template>

            <template v-slot:[`item.updated`]="{ item }">
              {{ convert_table_date(item.updated) }}
            </template>

            <template v-slot:[`item.censored`]="{ item }">
              <section v-if="item.revealed" :style="{ maxWidth: '300px' }">
                {{ item.censored }}
              </section>
              <section v-else>**********</section>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="item.revealed = !item.revealed"
                  >
                    <v-icon color="accent" v-if="item.revealed">
                      mdi-eye-off
                    </v-icon>
                    <v-icon color="accent" v-else> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span>Reveal "{{ item.tag }}"?</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="deleteTag(item.tag)"
                  >
                    <v-icon color="red"> mdi-trash-can </v-icon>
                  </v-btn>
                </template>
                <span>Delete "{{ item.tag }}"?</span>
              </v-tooltip>
            </template>
          </v-data-table>

          <v-divider></v-divider>
          <v-list class="pb-0 pt-0">
            <v-list-item
              :style="{ textAlignLast: 'center', marginRight: '0px' }"
              v-if="error_msg != ''"
            >
              <v-list-item-subtitle
                :style="{
                  whiteSpace: 'normal',
                  color: 'red',
                  fontWeight: 'bold',
                }"
                >{{ error_msg }}</v-list-item-subtitle
              >
            </v-list-item>
          </v-list>
          <v-divider v-if="error_msg != ''"></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogAddTag" width="500" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="green lighten-2"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="error_msg = ''"
                >
                  <v-icon left> mdi-plus </v-icon>
                  Add Tag
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5">
                  Add new censorship
                </v-card-title>

                <v-form
                  v-model="addTagFormValid"
                  v-on:submit.prevent
                  ref="addTagForm"
                >
                  <v-list class="pb-0 pt-0">
                    <v-list-item :style="{ marginRight: '0px' }">
                      <v-text-field
                        v-model="collection.newTag.tag"
                        :rules="$store.getters.rules.censorship.tag"
                        label="Censorship Tag"
                        autofocus
                        required
                      ></v-text-field>
                    </v-list-item>

                    <v-list-item :style="{ marginRight: '0px' }">
                      <v-text-field
                        v-model="collection.newTag.censored"
                        :rules="$store.getters.rules.censorship.censored"
                        label="Censored Data"
                        required
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-form>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn color="secondary" text @click="dialogAddTag = false">
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="addTag">
                    <v-icon left> mdi-content-save </v-icon>
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat outlined>
          <v-data-table
            dense
            :headers="tableHeadersInvites"
            :items="tableContentInvites"
            :items-per-page="5"
            class="elevation-0"
          >
            <template v-slot:[`item.created`]="{ item }">
              {{ convert_table_date(item.created) }}
            </template>

            <template v-slot:[`item.updated`]="{ item }">
              {{ convert_table_date(item.updated) }}
            </template>

            <template v-slot:[`item.invite_code`]="{ item }">
              <section v-if="item.revealed">
                {{ item.invite_code }}
              </section>
              <section v-else>
                {{ `${item.invite_code}`.replace(/.+?/g, "*") }}
              </section>
            </template>

            <template v-slot:[`item.expires_at`]="{ item }">
              {{ convert_table_date(item.expires_at) }}
            </template>

            <template v-slot:[`item.max_uses`]="{ item }">
              {{ item.max_uses ? item.max_uses : `unlimited` }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="accent" v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ item.note }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="item.revealed = !item.revealed"
                  >
                    <v-icon color="accent" v-if="item.revealed">
                      mdi-eye-off
                    </v-icon>
                    <v-icon color="accent" v-else> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span
                  >Reveal "{{
                    item.revealed
                      ? item.invite_code
                      : `${item.invite_code}`.replace(/.+?/g, "*")
                  }}" code?</span
                >
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="copyPaste(item.invite_code)"
                  >
                    <v-icon color="primary"> mdi-content-copy </v-icon>
                  </v-btn>
                </template>
                <span
                  >Copy "{{
                    item.revealed
                      ? item.invite_code
                      : `${item.invite_code}`.replace(/.+?/g, "*")
                  }}" to clipboard?</span
                >
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="revokeInvite(item.invite_code, item.revoked)"
                  >
                    <v-icon :color="item.revoked ? `green` : `red`">
                      {{ item.revoked ? `mdi-plus-box` : `mdi-minus-box` }}
                    </v-icon>
                  </v-btn>
                </template>
                <span
                  >Revoke "{{
                    item.revealed
                      ? item.invite_code
                      : `${item.invite_code}`.replace(/.+?/g, "*")
                  }}"?</span
                >
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="deleteInvite(item.invite_code)"
                  >
                    <v-icon color="red"> mdi-trash-can </v-icon>
                  </v-btn>
                </template>
                <span
                  >Delete "{{
                    item.revealed
                      ? item.invite_code
                      : `${item.invite_code}`.replace(/.+?/g, "*")
                  }}"?</span
                >
              </v-tooltip>
            </template>
          </v-data-table>

          <v-divider></v-divider>
          <v-list class="pb-0 pt-0">
            <v-list-item
              :style="{ textAlignLast: 'center', marginRight: '0px' }"
              v-if="error_msg != ''"
            >
              <v-list-item-subtitle
                :style="{
                  whiteSpace: 'normal',
                  color: 'red',
                  fontWeight: 'bold',
                }"
                >{{ error_msg }}</v-list-item-subtitle
              >
            </v-list-item>
          </v-list>
          <v-divider v-if="error_msg != ''"></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogAddInvite" width="500" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="green lighten-2"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="error_msg = ''"
                >
                  <v-icon left> mdi-plus </v-icon>
                  Add Invite Code
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5">
                  Add new Invite Code
                </v-card-title>

                <v-form
                  v-model="addInviteFormValid"
                  v-on:submit.prevent
                  ref="addInviteForm"
                >
                  <v-list class="pb-0 pt-0">
                    <v-list-item :style="{ marginRight: '0px' }">
                      <v-text-field
                        v-model="collection.newInvite.invite_code"
                        :rules="$store.getters.rules.censorship.invite_code"
                        label="Censorship Invite Code"
                        required
                      >
                        <template v-slot:append-outer>
                          <v-btn
                            icon
                            @click="
                              collection.newInvite.invite_code = Array(6)
                                .fill()
                                .map((_) =>
                                  String.fromCharCode(
                                    33 + Math.random() * (127 - 33)
                                  )
                                )
                                .join('')
                            "
                          >
                            <v-icon> mdi-reload </v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-list-item>

                    <v-list-item :style="{ marginRight: '0px' }">
                      <v-dialog
                        ref="datePickerDialog"
                        v-model="datePicker"
                        :return-value.sync="collection.newInvite.expires_at"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="collection.newInvite.expires_at"
                            :rules="$store.getters.rules.censorship.expires_at"
                            label="Expires at"
                            required
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template v-slot:append-outer>
                              <v-icon> mdi-calendar </v-icon>
                            </template>
                          </v-text-field>
                        </template>

                        <v-date-picker
                          v-model="collection.newInvite.expires_at"
                          color="primary"
                          :min="new Date().toISOString()"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="secondary"
                            @click="datePicker = false"
                          >
                            Cancel
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.datePickerDialog.save(
                                collection.newInvite.expires_at
                              )
                            "
                          >
                            Select
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-list-item>

                    <v-list-item>
                      <v-text-field
                        v-model="collection.newInvite.max_uses"
                        :rules="$store.getters.rules.censorship.max_uses"
                        label="Max uses"
                        hint="Can be empty (None)"
                      >
                        <template v-slot:append-outer>
                          <v-icon> mdi-alarm-plus </v-icon>
                        </template>
                      </v-text-field>
                    </v-list-item>

                    <v-list-item :style="{ marginRight: '0px' }">
                      <v-text-field
                        v-model="collection.newInvite.note"
                        :rules="$store.getters.rules.censorship.note"
                        label="Note to indentify Invite Code"
                        required
                      >
                        <template v-slot:append-outer>
                          <v-icon> mdi-message-bulleted </v-icon>
                        </template>
                      </v-text-field>
                    </v-list-item>
                  </v-list>
                </v-form>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn
                    color="secondary"
                    text
                    @click="dialogAddInvite = false"
                  >
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="addInvite">
                    <v-icon left> mdi-content-save </v-icon>
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import axios from "axios";

function initialState() {
  return {
    currentTab: null, // Holds current selected tab
    error_msg: "", // Holds error message if available
    dialogAddTag: false, // hold dialog toggle
    dialogAddInvite: false, // Holds dialog toggle
    addTagFormValid: false, // Holds form validator when creating a new tag
    addInviteFormValid: false, // Holds form validator when creating a new tag
    datePicker: false, // Indicator if date picker is shown

    tableHeadersTags: [
      { text: "Created", value: "created" },
      { text: "Updated", value: "updated" },
      { text: "Tag", value: "tag" },
      { text: "Censored", value: "censored" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    tableContentTags: [
      {
        created: "2022-08-27T05:02:50",
        censored: "my-dev.app@domainsbyproxy.com",
        updated: "2022-08-27T05:18:59",
        tag: "email",
      },
    ],

    tableHeadersInvites: [
      { text: "Created", value: "created" },
      { text: "Updated", value: "updated" },
      { text: "Invite Code", value: "invite_code" },
      { text: "Revoked", value: "revoked" },
      { text: "Expires At", value: "expires_at" },
      { text: "Total Uses", value: "total_uses" },
      { text: "Max allowed uses", value: "max_uses" },
      { text: "Total Fuses", value: "total_fuses" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    tableContentInvites: [
      {
        created: "2022-08-27T05:02:50",
        updated: "2022-08-27T05:02:50",
        invite_code: "654BD6",
        revoked: false,
        expires_at: "2022-09-27T05:02:50",
        total_uses: 45,
        total_fuses: 12,
        notes: "Lorem Ipsum",
      },
    ],

    collection: {
      newTag: {
        tag: "", // Holds new tag when adding new tag
        censored: "", // Holds text to censor when adding new tag
      },
      newInvite: {
        expires_at: null, // Holds expire date
        invite_code: null, // Holds invite code
        max_uses: null, // Holds the total amount of allowed uses
        note: null, // Holds the note of the invite
      },
    },
  };
}

export default {
  name: "CensorshipSettings",

  data: function () {
    return initialState();
  },

  created() {
    this.clear_state();
  },

  mounted() {
    this.load();
  },

  methods: {
    clear_state() {
      // Resets $data on closing dialog
      Object.assign(this.$data, initialState());
    },

    load() {
      axios
        .get("/api/v0/censorship/tags", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.access_token,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$data.tableContentTags = response.data.map((x) => {
              return {
                ...x,
                // Add additional parameters to data for table
                revealed: false,
              };
            });
          }
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            console.error(e.response.data.message);
            this.$data.error_msg = e.response.data.message;
          }
        });

      axios
        .get("/api/v0/censorship/invites", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.access_token,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$data.tableContentInvites = response.data.map((x) => {
              return {
                ...x,
                // Add additional parameters to data for table
                revealed: false,
              };
            });
          }
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            console.error(e.response.data.message);
            this.$data.error_msg = e.response.data.message;
          }
        });
    },

    convert_table_date: function (data) {
      let date = new Date(data);
      let options = {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return date.toLocaleString("nl-NL", options); //do. 12 mei 2022
    },

    addTag() {
      // Adds new censor tag
      if (this.$refs.addTagForm.validate()) {
        axios
          .post(
            "/api/v0/censorship/tag",
            {
              ...this.$data.collection.newTag,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.access_token,
              },
            }
          )
          .then(() => {
            this.$data.dialogAddTag = false;
          })
          .catch((e) => {
            if (e.response.data["message"]) {
              this.$data.error_msg = e.response.data.message;
              console.error(e.response.data.message);
            }
          })
          .finally(() => {
            this.load();
          });
      }
    },

    deleteTag(targetTag) {
      axios
        .delete("/api/v0/censorship/tag", {
          data: {
            tag: targetTag,
          },
          headers: {
            Authorization: "Bearer " + this.$store.getters.access_token,
          },
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            this.$data.error_msg = e.response.data.message;
            console.error(e.response.data.message);
          }
        })
        .finally(() => {
          this.load();
        });
    },

    addInvite() {
      // Adds new censor tag
      if (this.$refs.addInviteForm.validate()) {
        axios
          .post(
            "/api/v0/censorship/invite",
            {
              ...this.$data.collection.newInvite,
              expires_at: new Date(
                this.$data.collection.newInvite.expires_at
              ).toISOString(),
              max_uses:
                this.$data.collection.newInvite.max_uses == ""
                  ? null
                  : this.$data.collection.newInvite.max_uses,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.access_token,
              },
            }
          )
          .then(() => {
            this.$data.dialogAddInvite = false;
          })
          .catch((e) => {
            if (e.response.data["message"]) {
              this.$data.error_msg = e.response.data.message;
              console.error(e.response.data.message);
            }
          })
          .finally(() => {
            this.load();
          });
      }
    },

    deleteInvite(targetInviteCode) {
      axios
        .delete("/api/v0/censorship/invite", {
          data: {
            invite_code: targetInviteCode,
          },
          headers: {
            Authorization: "Bearer " + this.$store.getters.access_token,
          },
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            this.$data.error_msg = e.response.data.message;
            console.error(e.response.data.message);
          }
        })
        .finally(() => {
          this.load();
        });
    },

    revokeInvite(targetInviteCode, revoked) {
      axios
        .patch(
          "/api/v0/censorship/invite",
          {
            invite_code: targetInviteCode,
            revoked: !revoked,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.access_token,
            },
          }
        )
        .catch((e) => {
          if (e.response.data["message"]) {
            this.$data.error_msg = e.response.data.message;
            console.error(e.response.data.message);
          }
        })
        .finally(() => {
          this.load();
        });
    },

    async copyPaste(data) {
      // Copies data to clipboard
      await navigator.clipboard.writeText(data);
    },
  },

  watch: {
    dialogAddTag: function (value) {
      // Clears add tag data
      if (!value) {
        this.$data.collection.newTag.tag = "";
        this.$data.collection.newTag.censored = "";
      }
    },
    dialogAddInvite: function (value) {
      // Clears new invite data
      if (!value) {
        this.$data.collection.newInvite.expires_at = null;
        this.$data.collection.newInvite.invite_code = null;
        this.$data.collection.newInvite.max_uses = null;
        this.$data.collection.newInvite.note = null;
      }
    },
  },
};
</script>
