<template>
  <v-card flat outlined @click.native="error_msg = ''" :ripple="false">
    <v-card-title>User Accounts</v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle>Members</v-card-subtitle>

    <v-data-table
      dense
      :headers="tableHeaders"
      :items="tableContent"
      :items-per-page="5"
      class="elevation-0"
    >
      <template v-slot:[`item.created`]="{ item }">
        {{ convert_table_date(item.created) }}
      </template>

      <template v-slot:[`item.updated`]="{ item }">
        {{ convert_table_date(item.updated) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="deleteUser(item.username)"
            >
              <v-icon color="red"> mdi-trash-can </v-icon>
            </v-btn>
          </template>
          <span>Delete "{{ item.tag }}"?</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider v-if="error_msg != ''"></v-divider>
    <v-list class="pb-0 pt-0">
      <v-list-item
        :style="{ textAlignLast: 'center', marginRight: '0px' }"
        v-if="error_msg != ''"
      >
        <v-list-item-subtitle
          :style="{
            whiteSpace: 'normal',
            color: 'red',
            fontWeight: 'bold',
          }"
          >{{ error_msg }}</v-list-item-subtitle
        >
      </v-list-item>
    </v-list>

    <v-divider v-if="error_msg != ''"></v-divider>
  </v-card>
</template>

<script>
import axios from "axios";

function initialState() {
  return {
    error_msg: "", // Holds error message if available
    tableHeaders: [
      { text: "Created", value: "created" },
      { text: "Updated", value: "updated" },
      { text: "Username", value: "username" },
      { text: "E-Mail", value: "email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    tableContent: [
      {
        created: "2022-08-27T05:02:50",
        email: "my-dev.app@domainsbyproxy.com",
        updated: "2022-08-27T05:18:59",
        username: "admin",
      },
    ],
  };
}

export default {
  name: "UserAccountsSettings",

  data: function () {
    return initialState();
  },

  created() {
    this.clear_state();
  },

  mounted() {
    this.load();
  },

  methods: {
    clear_state() {
      // Resets $data on closing dialog
      Object.assign(this.$data, initialState());
    },

    load() {
      axios
        .get("/api/v0/users/", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.access_token,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$data.tableContent = response.data;
          }
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            this.$data.error_msg = e.response.data.message;
            console.error(e.response.data.message);
          }
        });
    },

    convert_table_date: function (data) {
      let date = new Date(data);
      let options = {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return date.toLocaleString("nl-NL", options); //do. 12 mei 2022
    },

    deleteUser(targetUser) {
      axios
        .delete("/api/v0/users/", {
          data: {
            username: targetUser,
          },
          headers: {
            Authorization: "Bearer " + this.$store.getters.access_token,
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.$data.error_msg = "";
          }
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            this.$data.error_msg = e.response.data.message;
            console.error(e.response.data.message);
          }
        })
        .finally(() => {
          this.load();
        });
    },
  },
};
</script>
