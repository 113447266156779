<template>
  <v-container>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
        <v-card>
          <v-tabs v-model="current_tab" vertical grow>
            <v-tab>
              <v-icon> mdi-calendar-check-outline </v-icon>
              <v-spacer></v-spacer>
              Availability
            </v-tab>
            <v-tab>
              <v-icon> mdi-earth </v-icon>
              <v-spacer></v-spacer>
              Global Settings
            </v-tab>
            <v-tab>
              <v-icon> mdi-account </v-icon>
              <v-spacer></v-spacer>
              User Accounts
            </v-tab>
            <v-tab>
              <v-icon> mdi-blur </v-icon>
              <v-spacer></v-spacer>
              Censorship Settings
            </v-tab>
            <v-tab>
              <v-icon> mdi-chart-line </v-icon>
              <v-spacer></v-spacer>
              Skills
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-tabs-items v-model="current_tab">
            <v-tab-item>
              <AvailabilityStatus />
            </v-tab-item>
            <v-tab-item>
              <GlobalSettings />
            </v-tab-item>

            <v-tab-item>
              <UserAccountsSettings />
            </v-tab-item>

            <v-tab-item>
              <CensorshipSettings />
            </v-tab-item>

            <v-tab-item>
              <SkillDashboard />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AvailabilityStatus from "@/components/dashboard/availibility.vue";
import GlobalSettings from "@/components/dashboard/global_settings.vue";
import UserAccountsSettings from "@/components/dashboard/user_accounts.vue";
import CensorshipSettings from "@/components/dashboard/censorship_settings.vue";
import SkillDashboard from "@/components/dashboard/skills.vue";

function initialState() {
  return {
    current_tab: null, // Holds current selected tab index
  };
}

export default {
  name: "DashboardView",

  data: function () {
    return initialState();
  },

  created() {
    this.clear_state();
  },

  components: {
    AvailabilityStatus,
    GlobalSettings,
    UserAccountsSettings,
    CensorshipSettings,
    SkillDashboard,
  },

  methods: {
    clear_state() {
      // Resets $data on closing dialog
      Object.assign(this.$data, initialState());
    },
  },
};
</script>
