import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  rules: {
    generic: {
      required: (value) => !!value || "Field is required.",
    },

    auth_totp: {
      username: [
        // Rules for the username
        (v) => !!v || "Username is required",
        (v) => v.length < 17 || "Max 16 characters",
        (v) => v.length > 4 || "Min 5 characters",
        (v) => /^([A-Za-z0-9\_]+)$/.test(v) || "Only: aBc_0123",  // eslint-disable-line
      ],
      email: [
        (v) => !!v || "E-mail address is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Must be a valid e-mail address",
      ],
      password: [
        // Rules for the password
        (v) => !!v || "Password is required",
        (v) => v.length > 5 || "Min 6 characters",
        (v) => (v && /\d/.test(v)) || "At least one digit",  // eslint-disable-line
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one Capital letter",  // eslint-disable-line
        (v) => (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",  // eslint-disable-line
        (v) => v.length < 37 || "Max 36 characters",
      ],
      otp: [
        (v) => !!v || "2FA is required",
        (v) => /^([\d]+)$/.test(v) || "Only digits",  // eslint-disable-line
        (v) => v.length == 6 || "Expect 6 characters",
      ],
    },

    censorship: {
      tag: [
        // Rules for the tag of a censorship
        (v) => !!v || "Tag is required",
      ],
      censored: [
        // Rules for the censored data
        (v) => !!v || "Censorship is required",
      ],
      invite_code: [
        // Rules for the invite code for unsencored data
        (v) => !!v || "Invite Code is required",
      ],
      expires_at: [
        // Rules for the expiration date of a invite code
        (v) => !!v || "Expire date is required",
      ],
      max_uses: [
        // Rules for the total amount of max uses in an invite code
        (v) => (v && /\d/.test(v) || v == null || v == "") || "At least one digit",  // eslint-disable-line
        (v) => (v && /^[0-9]+$/.test(v) || v == null || v == "") || "Only digits are allowed",  // eslint-disable-line
      ],
      note: [
        // Rules for the tag of a censorship
        (v) => !!v || "Note is required",
      ],
    },

    skills: {
      name: [(v) => !!v || "Skill name is required!"],
      description: [(v) => !!v || "Skill description is required!"],
      tooltip: [(v) => !!v || "Tooltip for a skill is required!"],
      experience: [
        (v) => !!v || "Experience amount is required!",
        (v) =>
          (!isNaN(parseFloat(v)) && v >= 1 && v <= 10) ||
          "Number has to be between 1 and 10",
      ],
      enjoyment: [
        (v) => !!v || "Enjoyment amount is required!",
        (v) =>
          (!isNaN(parseFloat(v)) && v >= 1 && v <= 10) ||
          "Number has to be between 1 and 10",
      ],
      imageUrl: [(v) => !!v || "Skill logo is required!"],
      website: [(v) => !!v || "Website to skill source is required!"],
    },

    availability: {
      status: [(v) => !!v || "Availability status is required!"],
      color: [
        (v) => !!v || "Color for status is required!",
        (v) => !v.includes("#") || "Hash tag is required, #RGBA!",
      ],
    },
  },
};

const getters = {
  rules: (state) => state.rules,
};

export default {
  state,
  getters,
};
