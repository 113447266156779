<template>
  <label v-if="revealed != ''" class="censorship_revealed">{{
    !hidden ? revealed : ""
  }}</label>
  <v-progress-linear
    v-else-if="is_loading"
    indeterminate
    color="accent"
    :style="{ maxWidth: '100px', display: 'inline-block' }"
  ></v-progress-linear>
  <v-dialog v-else v-model="dialogOpen" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="$props.placeholder != '' && !hidden"
            @click="dialogOpen = true"
            v-bind="attrs"
            v-on="on"
            class="censorship_placeholder"
            elevation="0"
            :ripple="false"
            depressed
            text
            plain
            small
            tile
          >
            {{ $props.placeholder }}
          </v-btn>
          <v-btn
            v-else-if="!hidden"
            @click="dialogOpen = true"
            v-bind="attrs"
            v-on="on"
            class="censorship"
            elevation="0"
            :ripple="false"
            depressed
            text
            plain
            small
            tile
          >
            {{ `${$props.tag + generate_string(6)}`.replace(/.+?/g, "_") }}
          </v-btn>
        </template>
        <span>Remove censorship?</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="text-h5"> Unlock Censorship </v-card-title>
      <v-card-subtitle>
        Unlocking the censorship required an invite code
      </v-card-subtitle>
      <v-divider></v-divider>

      <v-form v-model="unlockFormValid" v-on:submit.prevent ref="form">
        <v-list class="pb-0 pt-0">
          <v-list-item :style="{ marginRight: '0px' }">
            <v-text-field
              v-model="invite_code"
              :rules="$store.getters.rules.censorship.invite_code"
              label="Invite Code"
              autofocus
              required
            ></v-text-field>
          </v-list-item>
        </v-list>
      </v-form>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="secondary" text @click="clear_state"> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="unlock">
          <v-icon left> mdi-lock-open-outline </v-icon>
          Unlock
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

function initialState() {
  return {
    dialogOpen: false, // Indicator if the invite code dialog is open
    unlockFormValid: false, // Validator form
    invite_code: "", // Holds invite code
    is_loading: false, // load indicator
  };
}

export default {
  name: "CensorshipTag",

  props: {
    tag: {
      // Holds the Tag of the censorship
      type: String,
      default: "Tag",
    },
    placeholder: {
      // Holds a unrevealed placeholder instead of censorship
      type: String,
      default: "",
    },
    hidden: {
      // If true hides output
      type: Boolean,
      default: false,
    },
    overwriteValue: {
      // If set, overwrites and ignores tag values, IMPORTANT; only use for methods or computed elements
      type: String,
      default: null,
    },
  },

  data: function () {
    return {
      revealed: "", // Holds revealed content if available
      ...initialState(),
    };
  },

  created() {
    this.clear_state();
  },

  computed: {
    invite_code_censorship: function () {
      return this.$store.getters.invite_code_censorship;
    },
  },

  methods: {
    clear_state() {
      // Resets $data on closing dialog
      Object.assign(this.$data, initialState());
    },
    generate_string(lenght) {
      return Array(lenght)
        .fill()
        .map(() => String.fromCharCode(33 + Math.random() * (127 - 33)))
        .join("");
    },
    unlock() {
      if (this.$refs.form.validate()) {
        console.log("Unlocking ", this.$data.invite_code);
        this.$store.commit("resetCensorshipState");
        this.$store.commit("setInviteCode", this.$data.invite_code);
        this.$data.dialogOpen = false;
      }
    },
    loadData() {
      if (!this.$props.overwriteValue && this.invite_code_censorship) {
        this.$data.is_loading = true;
        axios
          .post(
            "/api/v0/censorship/check",
            {
              invite_code: this.invite_code_censorship,
              tag: this.$props.tag,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.access_token,
              },
            }
          )
          .then((response) => {
            this.$data.revealed = response.data.censored;
            this.$emit("onReveal", response.data.censored);
            this.clear_state();
          })
          .catch((e) => {
            if (e.response.data["message"]) {
              if (e.response.data.message.includes("invalid")) {
                this.$emit("onInvalid", e.response.data.message);
              }
              console.error(e.response.data.message);
            }
          })
          .finally(() => this.clear_state());
      } else {
        this.$data.revealed = this.$props.overwriteValue;
        this.$emit("onReveal", this.$props.overwriteValue);
        this.clear_state();
      }
    },
  },

  watch: {
    invite_code_censorship: {
      handler: function () {
        this.loadData();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.censorship {
  /* background-color: lightslategray;
  color: lightslategray; */
  background-color: #757575;
  color: #757575;
}

.censorship_revealed {
  font-family: cursive;
  font-style: italic;
}

.censorship_placeholder {
  padding: 0px !important;
}
</style>
