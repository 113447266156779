import Vue from "vue";
import Vuex from "vuex";
// Vuex Extensions
import validationRules from "./validation_rules";
import user from "./user";
import censorship from "./censorship";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    validationRules,
    user,
    censorship,
  },
});
