<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <v-container class="py-0 fill-height">
        <!-- <LocalizationChanger /> -->

        <v-spacer></v-spacer>

        <AuthorizeModify :current_route="$router.currentRoute.path" />
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <router-view />
      <BackToTop />
    </v-main>
  </v-app>
</template>

<script>
// import LocalizationChanger from "@/components/localization.vue";
import BackToTop from "@/components/backToTop.vue";
import AuthorizeModify from "@/components/auth_totp/framework.vue";

export default {
  name: "App",

  components: {
    // LocalizationChanger,
    AuthorizeModify,
    BackToTop,
  },
};
</script>
