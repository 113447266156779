<template>
  <v-card flat outlined @click.native="error_msg = ''" :ripple="false">
    <v-card-title>Global Settings</v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle>Preview</v-card-subtitle>
    <v-divider></v-divider>
    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex v-for="(skill, _) in skills" :key="_" xs6 sm3 lg2>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-card
                v-bind="attrs"
                v-on="on"
                hover
                elevation="0"
                width="150"
                height="150"
                :img="skill.image"
                rounded
                @click="skill.openDialog = !skill.openDialog"
              >
                <v-dialog
                  transition="dialog-top-transition"
                  max-width="600"
                  v-model="skill.openDialog"
                  overlay-opacity="50"
                  persistent
                >
                  <template v-slot:default>
                    <v-card class="mx-auto" outlined>
                      <v-list>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title class="text-h5 mb-1">
                              <v-btn icon @click="delete_skill(skill.name)">
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                              {{ skill.name }}
                            </v-list-item-title>

                            <v-card-text>
                              <small
                                >Experience: ({{
                                  skill.experience / 2
                                }}/5)</small
                              >
                              <v-rating
                                background-color="grey lighten-2"
                                color="primary"
                                empty-icon="mdi-star-outline"
                                full-icon="mdi-star"
                                half-icon="mdi-star-half-full"
                                half-increments
                                length="5"
                                readonly
                                :size="
                                  $vuetify.breakpoint.xs
                                    ? 8
                                    : $vuetify.breakpoint.sm
                                    ? 24
                                    : 32
                                "
                                :value="skill.experience / 2"
                              />
                              <small
                                >Enjoyment: ({{ skill.enjoyment / 2 }}/5)</small
                              >
                              <v-rating
                                background-color="grey lighten-2"
                                color="red"
                                empty-icon="mdi-heart-outline"
                                full-icon="mdi-heart"
                                half-icon="mdi-heart-half-full"
                                half-increments
                                length="5"
                                readonly
                                :size="
                                  $vuetify.breakpoint.xs
                                    ? 8
                                    : $vuetify.breakpoint.sm
                                    ? 24
                                    : 32
                                "
                                :value="skill.enjoyment / 2"
                              />
                            </v-card-text>
                          </v-list-item-content>
                          <v-list-item-content
                            :style="{
                              maxWidth: 150,
                            }"
                          >
                            <v-card
                              flat
                              height="150"
                              :img="skill.image"
                              rounded
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-divider></v-divider>

                      <v-card-subtitle :style="{ padding: 24 }">
                        {{ skill.description }}
                      </v-card-subtitle>

                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-btn
                          color="primary accent-2"
                          text
                          @click="openInNewWindow(skill.reference)"
                        >
                          Website
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red accent-2"
                          text
                          @click="skill.openDialog = false"
                        >
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-card>
            </template>
            <span>{{ skill.tooltip }}</span>
          </v-tooltip>
        </v-flex>
        <span
          v-if="skills.length == 0"
          :style="{ width: '100%', textAlignLast: 'center', color: 'grey' }"
          >Add skills to get started</span
        >
      </v-layout>
    </v-container>

    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="accent" text @click="clear_state"> Reload Skills </v-btn>
      <v-spacer></v-spacer>
      <v-dialog v-model="dialogAddSkill" width="800" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="green lighten-2"
            text
            v-bind="attrs"
            v-on="on"
            @click="dialogAddSkill = true"
          >
            <v-icon left> mdi-plus </v-icon>
            Add Skill
          </v-btn>
        </template>

        <v-card id="no-overflow-x">
          <v-card-title class="text-h5"> Add new Skill </v-card-title>

          <v-divider></v-divider>

          <v-row>
            <v-col cols="3">
              <v-container fluid grid-list-md>
                <v-layout row wrap>
                  <v-flex>
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          hover
                          elevation="0"
                          width="150"
                          height="150"
                          :img="new_skills.image"
                          rounded
                          @click="openDialog = !openDialog"
                        >
                          <v-dialog
                            transition="dialog-top-transition"
                            max-width="600"
                            v-model="openDialog"
                            overlay-opacity="50"
                            persistent
                          >
                            <template v-slot:default>
                              <v-card class="mx-auto" outlined>
                                <v-list>
                                  <v-list-item three-line>
                                    <v-list-item-content>
                                      <v-list-item-title class="text-h5 mb-1">
                                        {{ new_skills.name }}
                                      </v-list-item-title>

                                      <v-card-text>
                                        <small
                                          >Experience: ({{
                                            new_skills.experience / 2
                                          }}/5)</small
                                        >
                                        <v-rating
                                          background-color="grey lighten-2"
                                          color="primary"
                                          empty-icon="mdi-star-outline"
                                          full-icon="mdi-star"
                                          half-icon="mdi-star-half-full"
                                          half-increments
                                          length="5"
                                          readonly
                                          :size="
                                            $vuetify.breakpoint.xs
                                              ? 8
                                              : $vuetify.breakpoint.sm
                                              ? 24
                                              : 32
                                          "
                                          :value="new_skills.experience / 2"
                                        />
                                        <small
                                          >Enjoyment: ({{
                                            new_skills.enjoyment / 2
                                          }}/5)</small
                                        >
                                        <v-rating
                                          background-color="grey lighten-2"
                                          color="red"
                                          empty-icon="mdi-heart-outline"
                                          full-icon="mdi-heart"
                                          half-icon="mdi-heart-half-full"
                                          half-increments
                                          length="5"
                                          readonly
                                          :size="
                                            $vuetify.breakpoint.xs
                                              ? 8
                                              : $vuetify.breakpoint.sm
                                              ? 24
                                              : 32
                                          "
                                          :value="new_skills.enjoyment / 2"
                                        />
                                      </v-card-text>
                                    </v-list-item-content>
                                    <v-list-item-content
                                      :style="{
                                        maxWidth: 150,
                                      }"
                                    >
                                      <v-card
                                        flat
                                        height="150"
                                        :img="new_skills.image"
                                        rounded
                                      />
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>

                                <v-divider></v-divider>

                                <v-card-subtitle :style="{ padding: 24 }">
                                  {{ new_skills.description }}
                                </v-card-subtitle>

                                <v-divider></v-divider>
                                <v-card-actions>
                                  <v-btn
                                    color="primary accent-2"
                                    text
                                    @click="
                                      openInNewWindow(new_skills.reference)
                                    "
                                  >
                                    Website
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="red accent-2"
                                    text
                                    @click="openDialog = false"
                                  >
                                    Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>
                        </v-card>
                      </template>
                      <span>{{ new_skills.tooltip }}</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-col>

            <v-col>
              <v-form
                v-model="addSkillFormValid"
                v-on:submit.prevent
                ref="addSkillForm"
              >
                <v-list class="pb-0 pt-0">
                  <v-list-item :style="{ marginRight: '0px' }">
                    <v-text-field
                      v-model="new_skills.name"
                      :rules="$store.getters.rules.skills.name"
                      label="Name of the skill"
                      autofocus
                      required
                    ></v-text-field>
                  </v-list-item>

                  <v-list-item :style="{ marginRight: '0px' }">
                    <v-text-field
                      v-model="new_skills.description"
                      :rules="$store.getters.rules.skills.description"
                      label="Describe the skill"
                      required
                    ></v-text-field>
                  </v-list-item>

                  <v-list-item :style="{ marginRight: '0px' }">
                    <v-text-field
                      v-model="new_skills.tooltip"
                      :rules="$store.getters.rules.skills.tooltip"
                      label="Tooltip for the skill"
                      required
                    ></v-text-field>
                  </v-list-item>

                  <v-list-item :style="{ marginRight: '0px' }">
                    <v-text-field
                      v-model="new_skills.experience"
                      :rules="$store.getters.rules.skills.experience"
                      type="number"
                      label="Experience 1 out of 10"
                      required
                    ></v-text-field>
                  </v-list-item>

                  <v-list-item :style="{ marginRight: '0px' }">
                    <v-text-field
                      v-model="new_skills.enjoyment"
                      :rules="$store.getters.rules.skills.enjoyment"
                      type="number"
                      label="Enjoyment 1 out of 10"
                      required
                    ></v-text-field>
                  </v-list-item>

                  <v-list-item :style="{ marginRight: '0px' }">
                    <v-text-field
                      v-model="new_skills.image"
                      :rules="$store.getters.rules.skills.imageUrl"
                      label="Link to image of the logo for the skill"
                      required
                    ></v-text-field>
                  </v-list-item>

                  <v-list-item :style="{ marginRight: '0px' }">
                    <v-text-field
                      v-model="new_skills.website"
                      :rules="$store.getters.rules.skills.website"
                      label="Source of the skill (website for example)"
                      required
                    ></v-text-field>
                  </v-list-item>
                </v-list>
              </v-form>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-list class="pb-0 pt-0">
            <v-list-item
              :style="{ textAlignLast: 'center', marginRight: '0px' }"
              v-if="error_msg != ''"
            >
              <v-list-item-subtitle
                :style="{
                  whiteSpace: 'normal',
                  color: 'red',
                  fontWeight: 'bold',
                }"
                >{{ error_msg }}</v-list-item-subtitle
              >
            </v-list-item>
          </v-list>
          <v-divider v-if="error_msg != ''"></v-divider>

          <v-card-actions>
            <v-btn color="secondary" text @click="dialogAddSkill = false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="save">
              <v-icon left> mdi-content-save </v-icon>
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

function initialState() {
  return {
    error_msg: "", // Holds error message
    dialogAddSkill: false, // Indicator if add skill dialog is open
    addSkillFormValid: false, // Indicator if form is valid
    skills: [], // Holds all skills
    openDialog: false, // holds preview open dialog indicator
    new_skills: {
      // Holds various form inputs
      name: "", // Holds the name of the new tag
      description: "", // Holds the description of the new text
      experience: 1, // Holds the amount of experience for the skill
      enjoyment: 1, // Holds the amount of enjoyment for the skill
      image: "", // Holds image
      website: "", // Holds the website for the skill
    },
  };
}

export default {
  name: "SkillDashboard",

  data: function () {
    return initialState();
  },

  created() {
    this.clear_state();
  },

  mounted() {
    this.load();
  },

  methods: {
    clear_state() {
      // Resets $data on closing dialog
      Object.assign(this.$data, initialState());
      this.load();
    },

    load() {
      axios
        .get("/api/v1/skills/", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.access_token,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$data.skills = response.data.map((x) => {
              return { ...x, openDialog: false };
            });
          }
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            this.$data.error_msg = e.response.data.message;
            console.error(e.response.data.message);
          }
        });
    },

    save() {
      if (this.$refs.addSkillForm.validate()) {
        this.$data.error_msg = "";
        axios
          .post(
            "/api/v1/skills/add",
            {
              ...this.$data.new_skills,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.access_token,
              },
            }
          )
          .then((response) => {
            if (response.data.code == 200) {
              console.log("Saved!");
            }
          })
          .catch((e) => {
            if (e.response.data["message"]) {
              this.$data.error_msg = e.response.data.message;
              console.error(e.response.data.message);
            }
          })
          .finally(() => {
            if (this.$data.error_msg == "") {
              this.clear_state();
            }
          });
      }
    },

    delete_skill(name) {
      axios
        .delete("/api/v1/skills/delete", {
          data: {
            name: name,
          },
          headers: {
            Authorization: "Bearer " + this.$store.getters.access_token,
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            console.log("Removed!");
          }
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            this.$data.error_msg = e.response.data.message;
            console.error(e.response.data.message);
          }
        })
        .finally(() => {
          if (this.$data.error_msg == "") {
            this.clear_state();
          }
        });
    },
  },

  openInNewWindow(target) {
    window.open(target, "_blank");
  },
};
</script>

<style>
.v-dialog:has(> #no-overflow-x) {
  overflow-x: clip !important;
}
</style>
