<template>
  <v-card flat outlined @click.native="error_msg = ''" :ripple="false">
    <v-card-title>Global Settings</v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle>2FA Settings</v-card-subtitle>

    <v-form v-model="isFormValid" v-on:submit.prevent ref="form">
      <v-list class="pb-0 pt-0">
        <v-list-item :style="{ marginRight: '0px' }">
          <v-checkbox
            v-model="settings.auth_totp.allow_registration"
            label="Allow anonymous users to register a new account"
            required
          ></v-checkbox>
        </v-list-item>

        <v-list-item :style="{ marginRight: '0px' }">
          <v-checkbox
            v-model="settings.auth_totp.require_login"
            label="Require username and password when using 2FA"
            required
          ></v-checkbox>
        </v-list-item>

        <v-list-item :style="{ marginRight: '0px' }">
          <v-checkbox
            v-model="settings.auth_totp.require_email"
            label="Ask user for e-mail if unknown when using 2FA"
            required
          ></v-checkbox>
        </v-list-item>

        <v-list-item :style="{ marginRight: '0px' }">
          <v-checkbox
            v-model="settings.auth_totp.allow_skipping_email"
            label="When asked for e-mail allow user to skip this step"
            required
          ></v-checkbox>
        </v-list-item>
      </v-list>
    </v-form>

    <v-divider></v-divider>

    <v-list class="pb-0 pt-0">
      <v-list-item
        :style="{ textAlignLast: 'center', marginRight: '0px' }"
        v-if="error_msg != ''"
      >
        <v-list-item-subtitle
          :style="{
            whiteSpace: 'normal',
            color: 'red',
            fontWeight: 'bold',
          }"
          >{{ error_msg }}</v-list-item-subtitle
        >
      </v-list-item>
    </v-list>
    <v-divider v-if="error_msg != ''"></v-divider>

    <v-card-actions>
      <v-btn color="accent" text @click="load"> Reload Settings </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="save"> Save Changes </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

function initialState() {
  return {
    isFormValid: false, // Checks if the form is valid
    error_msg: "", // Holds error message if available
    settings: {
      auth_totp: {
        require_login: false,
        allow_registration: false,
        require_email: false,
        allow_skipping_email: false,
      },
    },
  };
}

export default {
  name: "GlobalSettings",

  data: function () {
    return initialState();
  },

  created() {
    this.clear_state();
  },

  mounted() {
    this.load();
  },

  methods: {
    clear_state() {
      // Resets $data on closing dialog
      Object.assign(this.$data, initialState());
    },

    load() {
      axios
        .get("/api/v0/auth/totp/settings")
        .then((response) => {
          if (response.status == 200) {
            this.$data.settings.auth_totp.allow_registration =
              response.data.allow_registration;
            this.$data.settings.auth_totp.allow_skipping_email =
              response.data.allow_skipping_email;
            this.$data.settings.auth_totp.require_email =
              response.data.require_email;
            this.$data.settings.auth_totp.require_login =
              response.data.require_login;
          }
        })
        .catch((e) => {
          if (e.response.data["message"]) {
            console.error(e.response.data.message);
            this.$data.error_msg = e.response.data.message;
          }
        });
    },

    save() {
      this.$data.error_msg = "";
      if (this.$refs.form.validate()) {
        axios
          .post(
            "/api/v0/auth/totp/settings",
            {
              ...this.$data.settings.auth_totp,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.access_token,
              },
            }
          )
          .then((response) => {
            if (response.data.code == 200) {
              this.$data.error_msg = "Saved!";
            }
          })
          .catch((e) => {
            if (e.response.data["message"]) {
              console.error(e.response.data.message);
              this.$data.error_msg = e.response.data.message;
            }
          })
          .finally(() => {
            this.$data.error_msg = "";
          });
      }
    },
  },
};
</script>
