<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        left
        color="primary"
        @click="toTop"
      >
        <v-icon> mdi-arrow-up-thick </v-icon>
      </v-btn>
    </template>
    <span>Back to top</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "BackToTop",

  props: {
    sensitivity: {
      // When should the button appear?
      type: Number,
      default: 500,
    },
  },

  data: () => ({
    fab: false,
  }),

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > this.$props.sensitivity;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
