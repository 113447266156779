var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[(
      !_vm.$store.getters.authenticated || _vm.is_email || _vm.is_login || _vm.is_register
    )?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-lock ")])],1)]}}],null,false,3577283907),model:{value:(_vm.login_menu),callback:function ($$v) {_vm.login_menu=$$v},expression:"login_menu"}},[(!_vm.is_register && !_vm.is_login && !_vm.is_email && _vm.require_login)?_c('v-card',[_c('v-list',{staticClass:"ma-0"},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0 ma-0",style:({ textAlignLast: 'center' })},[_c('v-list-item-title',[_vm._v("Authorization Required")]),_c('v-list-item-subtitle')],1)],1)],1),_c('v-divider'),_c('v-container',[_c('v-form',{ref:"login_form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-list',{staticClass:"pb-0 pt-0"},[_c('v-list-item',{style:({ marginRight: '0px' })},[_c('v-text-field',{staticClass:"pb-0 pt-0",attrs:{"rules":_vm.$store.getters.rules.auth_totp.username,"counter":16,"label":"Username","autofocus":"","required":""},model:{value:(_vm.collection.username),callback:function ($$v) {_vm.$set(_vm.collection, "username", $$v)},expression:"collection.username"}})],1),_c('v-list-item',{style:({ marginRight: '0px' })},[_c('v-text-field',{staticClass:"pb-0 pt-0",attrs:{"type":"password","rules":_vm.$store.getters.rules.auth_totp.password,"counter":36,"label":"Password","required":""},model:{value:(_vm.collection.password),callback:function ($$v) {_vm.$set(_vm.collection, "password", $$v)},expression:"collection.password"}})],1),(_vm.error_msg != '')?_c('v-list-item',{style:({ textAlignLast: 'center', marginRight: '0px' })},[_c('v-list-item-subtitle',{style:({
                  whiteSpace: 'normal',
                  color: 'red',
                  marginTop: '15px',
                })},[_vm._v(_vm._s(_vm.error_msg))])],1):_vm._e()],1)],1)],1),_c('v-card-actions',[(_vm.allow_registration)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.register}},[_vm._v(" Register ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.login}},[_vm._v(" Login ")])],1)],1):_vm._e(),(_vm.is_register && _vm.allow_registration)?_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0 ma-0",style:({ textAlignLast: 'center' })},[_c('v-list-item-title',[_vm._v("Scan QR Code")]),_c('v-list-item-subtitle',[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US","target":"_blank"}},[_vm._v("Google Authenticator")])])],1)],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0 ma-0",style:({ textAlignLast: 'center' })},[_c('v-img',{attrs:{"width":"200","height":"200","src":'https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=' +
                'otpauth%3A//totp/my-dev.app%3A' +
                _vm.collection.username +
                '%2540my-dev%3Fsecret%3D' +
                _vm.collection.secret +
                '%26issuer%3Dmy-dev.app&choe=UTF-8'}})],1)],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0 ma-0",style:({ textAlignLast: 'center' })},[_c('v-list-item-title',{style:({ fontSize: 'small' })},[_c('code',[_vm._v(_vm._s(_vm.$data.collection.secret))])])],1)],1)],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""},on:{"click":_vm.copySecret}},'v-btn',attrs,false),on),[_vm._v(" Copy ")])]}}],null,false,3291436048)},[_c('span',[_vm._v(_vm._s(_vm.$data.collection.secret))])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.is_register = false;
            _vm.is_login = true;}}},[_vm._v(" Continue ")])],1)],1):_vm._e(),(_vm.is_login || (!_vm.is_email && !_vm.require_login))?_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0 ma-0",style:({ textAlignLast: 'center' })},[_c('v-list-item-title',[_vm._v("Provide 2FA from")]),_c('v-list-item-subtitle',[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US","target":"_blank"}},[_vm._v("Google Authenticator")])])],1)],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-item',[_c('v-container',{style:({ textAlignLast: 'center' })},[_c('v-avatar',{attrs:{"size":"125"}},[_c('v-img',{attrs:{"src":require('@/assets/images/auth_totp/otp.png')}})],1)],1)],1)],1),_c('v-container',[_c('v-form',{ref:"otp_form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-list',{staticClass:"pb-0 pt-0"},[(!_vm.require_login)?_c('v-list-item',{style:({ marginRight: '0px' })},[_c('v-text-field',{staticClass:"pb-0 pt-0",attrs:{"rules":_vm.$store.getters.rules.auth_totp.username,"counter":16,"label":"Username","autofocus":!_vm.require_login,"required":""},model:{value:(_vm.collection.username),callback:function ($$v) {_vm.$set(_vm.collection, "username", $$v)},expression:"collection.username"}})],1):_vm._e(),_c('v-list-item',{style:({ marginRight: '0px' })},[_c('v-text-field',{staticClass:"pb-0 pt-0",attrs:{"rules":_vm.$store.getters.rules.auth_totp.otp,"counter":6,"label":"2FA Connection Code","autofocus":_vm.require_login,"required":""},model:{value:(_vm.collection.otp),callback:function ($$v) {_vm.$set(_vm.collection, "otp", $$v)},expression:"collection.otp"}})],1),(_vm.error_msg != '')?_c('v-list-item',{style:({ textAlignLast: 'center', marginRight: '0px' })},[_c('v-list-item-subtitle',{style:({
                  whiteSpace: 'normal',
                  color: 'red',
                  marginTop: '15px',
                })},[_vm._v(_vm._s(_vm.error_msg))])],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":_vm.checkAuthenticator}},[_vm._v(" Continue ")])],1)],1):_vm._e(),(_vm.is_email)?_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0 ma-0",style:({ textAlignLast: 'center' })},[_c('v-list-item-title',[_vm._v("Connect E-mail to")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.collection.username)+" ")])],1)],1)],1),_c('v-divider'),_c('v-container',[_c('v-form',{ref:"email_form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-list',{staticClass:"pb-0 pt-0"},[_c('v-list-item',{style:({ marginRight: '0px' })},[_c('v-text-field',{staticClass:"pb-0 pt-0",attrs:{"rules":_vm.$store.getters.rules.auth_totp.email,"label":"E-Mail Address","autofocus":"","required":""},model:{value:(_vm.collection.email),callback:function ($$v) {_vm.$set(_vm.collection, "email", $$v)},expression:"collection.email"}})],1),(_vm.error_msg != '')?_c('v-list-item',{style:({ textAlignLast: 'center', marginRight: '0px' })},[_c('v-list-item-subtitle',{style:({
                  whiteSpace: 'normal',
                  color: 'red',
                  marginTop: '15px',
                })},[_vm._v(_vm._s(_vm.error_msg))])],1):_vm._e()],1)],1)],1),_c('v-card-actions',[(_vm.allow_skipping_email)?_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":_vm.skipEmail}},[_vm._v(" Skip ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":_vm.updateEmail}},[_vm._v(" Save ")])],1)],1):_vm._e()],1):_vm._e(),(
      _vm.$store.getters.authenticated && !_vm.is_email && !_vm.is_login && !_vm.is_register
    )?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-Y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-menu ")])],1)]}}],null,false,3540298523),model:{value:(_vm.auth_menu),callback:function ($$v) {_vm.auth_menu=$$v},expression:"auth_menu"}},[_c('v-list',[_c('v-list-item',[_c('v-btn',{style:({ width: '100%' }),attrs:{"text":""},on:{"click":_vm.logout}},[_c('v-icon',[_vm._v(" mdi-logout-variant ")]),_c('v-spacer'),_vm._v(" Logout ")],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }